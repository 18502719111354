<template>
  <v-footer id="dashboard-core-footer">
    <v-container v-if="simple">
      <v-row align="center">
        <v-col
          cols="12"
        >
          <div class="text-body-1 font-weight-light text-center grey--text">
            EVERGROUP &copy; {{ new Date().getFullYear() }}
            <!-- , made with
            <v-icon
              size="18"
              color="red"
            >
              mdi-heart
            </v-icon>
            by
            <a
              href="/"
              class="black--text"
            >
              GueZarCode
            </a> for a better web. -->
          </div>
        </v-col>
      </v-row>
    </v-container>
    <span v-else>Este es el footer completo para las demas vistas</span>
  </v-footer>
</template>

<script>
  export default {
    name: 'AppFooter',

    props: {
      simple: Boolean,
    },

    data: () => ({
      links: [
        {
          href: '#',
          text: 'Creative Tim',
        },
        {
          href: '#',
          text: 'About Us',
        },
        {
          href: '#',
          text: 'Blog',
        },
        {
          href: '#',
          text: 'Licenses',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase

  .v-sheet.v-footer:not(.v-sheet--outlined)
    box-shadow: 0px !important
</style>
