export default () => [
  // {
  //   name: 'Home',
  //   icon: 'mdi-home',
  //   to: '/',
  // },
  {
    name: 'Login',
    icon: 'mdi-account',
    to: '/login',
  },
  // {
  //   name: 'Registro',
  //   icon: 'mdi-account-plus',
  //   to: '/signup',
  // },
]
