<template>
  <div>
    <app-navbar />
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="4"
          >
            <br><br><br>
            <base-material-card
              class="v-card-profile"
              avatar="/Login.jpg"
            >
              <v-card-text class="text-center">
                <h4 class="text-h3 font-weight-light mb-3 black--text">
                  Recuperar contraseña
                </h4>
              </v-card-text>
              <v-form
                v-model="isValid"
                @submit="e => save(e)"
              >
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.email"
                        :rules="emailRules"
                        required
                        name="email"
                        class="purple-input"
                        label="Email"
                        type="Email"
                        outlined
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="mb-5"
                    >
                      <v-btn
                        color="secondary"
                        class="mr-0"
                        block
                        type="submit"
                        :loading="fetchingLogin"
                      >
                        Enviar &nbsp; <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="mb-1" />
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
          </v-col>
        </v-row>
      </v-container>
      <br><br><br>
      <app-footer simple />
    </v-main>
  </div>
</template>

<script>
  import AppNavbar from '@/components/Navbar.vue'
  import AppFooter from '@/components/Footer.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'RecoverPassword',

    components: {
      AppNavbar,
      AppFooter,

    },

    data () {
      return {
        isValid: false,
        show1: false,
        show2: false,
        repeatPass: '',
        emailRules: [
          v => !!v || 'El email es requerido',
          v => /.+@.+/.test(v) || 'Debe ingresar un email valido',
        ],
        form: {
          email: '',
        },
      }
    },

    computed: {
      ...mapState(['fetchingLogin']),
    },

    methods: {
      ...mapActions(['recoverPassword']),
      async save (e) {
        e.preventDefault()

        if (this.isValid) {
          await this.recoverPassword(this.form)
          setTimeout(() => {
            this.$router.push('/login')
          }, 3000)
        }
      },
    },

  }
</script>
