<template>
  <div>
    <v-app-bar
      id="app-bar"
      absolute
      app
      color="primary"
      dark
      flat
      height="75"
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile"
        @click="drawer = true"
      />

      <v-toolbar-title
        class="font-weight-light"
        :style="{
          fontSize: '15pt'
        }"
        v-text="'Sistema Génerico'"
      />

      <v-spacer />

      <div class="mx-3" />

      <div
        v-if="!$vuetify.breakpoint.mobile"
        id="links"
      >
        <v-btn
          v-for="(link, i) in links"
          :key="i"
          class="ml-2"
          text
          :to="link.to"
        >
          <span>
            <v-icon>{{ link.icon }}</v-icon> {{ link.name }}
          </span>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      width="75%"
    >
      <v-app-bar
        color="primary"
        flat
        height="75"
      >
        <v-spacer />
        <v-icon
          class="mr-3 white--text"
          @click="drawer = !drawer"
        >
          mdi-close
        </v-icon>
      </v-app-bar>

      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="secondary--text"
        >
          <v-list-item
            v-for="(link, i) in links"
            :key="i"
            :to="link.to"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import linksMixin from '@/mixins/linksMixin.js'
  export default {
    name: 'AppNavbar',

    mixins: [linksMixin],

    data () {
      return {
        drawer: false,
        group: null,
      }
    },

  }
</script>
