import loggedLinks from '../data/loggedLinks.js'
import unloggedLinks from '../data/unloggedLinks.js'
import { SUPER_ADMIN } from '../const.js'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user', 'access']),
    links () {
      return !this.user ? unloggedLinks() : this.linksByRol
    },

    linksByRol () {
      return this.user.role === SUPER_ADMIN
        ? loggedLinks()
        : loggedLinks().filter(
          (link) => link.access.find((access) => access === this.user.role),
        )
    },
  },
}
