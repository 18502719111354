import { SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, WAREHOUSE_MANAGER, SELLER } from '../const'
export default () => [
  // {
  //   name: 'Home',
  //   icon: 'mdi-home',
  //   to: '/',
  //   access: [ADMIN, USER, CLIENT],
  // },
  // {
  //   name: 'Dashboard',
  //   icon: 'mdi-view-dashboard',
  //   to: '/admin/dashboard',
  //   access: [ADMIN, USER, CLIENT],
  // },
  // {
  //   name: 'Administracion de usuarios',
  //   icon: 'mdi-account',
  //   to: '/admin/dashboard',
  //   access: [ADMIN],
  // },
  // {
  //   name: 'Impresion de Pdf',
  //   icon: 'mdi-file-pdf-box',
  //   to: '/admin/dashboard',
  //   access: [],
  // },
  {
    name: 'Cerrar sesion',
    icon: 'mdi-exit-to-app',
    to: '/close-session',
    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, WAREHOUSE_MANAGER, SELLER],
  },
]
